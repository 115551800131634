import React, { useState } from "react";
import './navbar.css';
import {FaBars, Fabars, FaTimes} from 'react-icons/fa'


const Navbar = () => {

    const [click, setClick] = useState(false)

    const handleClick = () => setClick(!click);


    return(

        <div className="header">
            <div className="container">

                <h1>RA<span className="primary">N</span>DY<span className="primary">.</span>tv</h1>

                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li>
                        <a href="https://randy-tv.com/">Home</a>
                    </li>

                    <li>
                        <a href="/">Live</a>
                    </li>

                    <li>
                        <a href="/">Shows</a>
                    </li>

                    <li>
                        <a href="/">Sports</a>
                    </li>

                    <li>
                        <a href="">News</a>
                    </li>

                    <li>
                        <a href="">Crypto</a>
                    </li>

                </ul>

                <div className="hamburger" onClick={handleClick}>
                    {click ? (<FaTimes size={20} style={{color: '#333'}}/>) : (<FaBars size={20} style={{color: '#333'}} /> )}
                    
                </div>

            </div>
            
        </div>

    )
}


export default Navbar;