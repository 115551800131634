import React from "react";
import './episodes.css'
import {DefaultPlayer as Video} from 'react-html5video'
import 'react-html5video/dist/styles.css'
import E1 from '../assets/video/e1ernesto.mp4'
import pic from '../assets/ernestosgameshowlogo.png'
import phone from '../assets/video/e1ernesto-iphone.mp4'



const Episodes = () => {
    return (

        <div className="e-container">
            
            <text className="title">Episodes</text>

            <div className="episode-list">


                <div className="e-1">
                    <Video autoplay loop className='vid' poster={pic}>
                        <source src={E1} type="video/mp4"/>
                    </Video>

                    <div className="words">Ernesto's Game Show____ep.1</div>


                    
                    <Video autoplay loop className='iphone' poster={pic}>
                        <source src={phone} type="video/mp4"/>
                    </Video>

                </div>



            </div>
        </div>

    )
}

export default Episodes;